//TODO：可以考虑将页头的几个按钮做成可配置的方式
import { LineLayout } from '@/layout'
import { searchParams } from '@/utils'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { ExportReportButton } from '@/components/exportReportButton'
import './index.scss'
interface Props {
  name: string,
  leftIcon?: 'back' | 'workShop'
}
const requestFullScreen = () => {
  document.body.requestFullscreen && document.body.requestFullscreen()
}
const lineCode = searchParams().lineCode
const toolbarIsVisiable = searchParams().toolbarIsVisiable
const Header = (props: Props) => {
  const history = useHistory()
  //解决退出全屏的兼容问题
  const exitFullScreen = () => {
    let d: any = document
    if (d.cancelFullScreen) {
      d.cancelFullScreen()
    } else if (d.mozCancelFullScreen) {
      d.mozCancelFullScreen()
    } else if (d.webkitCancelFullScreen) {
      d.webkitCancelFullScreen()
    } else if (d.msExitFullscreen) {
      d.msExitFullscreen()
    }
  }

  const back = () => {
    //@ts-ignore
    history.goBack()
  }
  const checkFull = () => {
    let d: any = document
    return Boolean(d.fullscreenElement || d.mozFullScreenElement || d.webkitFullscreenElement || d.msFullscreenElement)
  }

  //进入或者退出全屏
  const toggle = () => {
    if (checkFull()) {
      exitFullScreen()
      return false
    }
    requestFullScreen()
  }

  //返回首页
  const tohome = () => {
    history.push('/')
  }

  return (
    <div className="header-content content-bg">
      <LineLayout width={105} margin={[0, 0, 0, 15]} padding={[7, 15, 7, 15]}>
        {
          toolbarIsVisiable === 'false' ? <div /> : <div className='back-btn' onClick={back}></div>
        }

      </LineLayout>
      <span>{props.name}</span>

      {
        toolbarIsVisiable === 'false' ? <div /> :
          <div className="head-tools">
            <LineLayout justify='between' width={100} height={40}>

              <ExportReportButton />
              <div className="home" onClick={tohome} />
              <div className="full-screen" onClick={toggle} />
            </LineLayout>
          </div>
      }

  

      {/* {
        toolbarIsVisiable === 'false' ? <div /> : <div className="full-screen" onClick={toggle}></div>
      } */}


    </div >
  )
}
export default Header
