import React, { useState } from 'react'
import { Layout, LineLayout } from '../../../layout'
import { Dashboard } from '../../../components/chart/dashboard'
import { useHistoryTravel, useRequest } from 'ahooks'
import { Equipment, Production, Call } from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash'
import './index.scss'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { useHistory } from 'react-router-dom'

let content = <Loading />
export const LineEntry = () => {
    // 先只去获取单条产线的 其他写死 反正也没生产。没有对应的接口


    let { loading, error, data } = useRequest(() => {
        return Promise.all(
            [
                Equipment.getCurrentElectricityPowerInW('1'),
                Production.getTotalCount('1'),
                Equipment.getTotalElectricityQuantityInKWH('1'),
                Call.hasCallButtonSystem(),
            ])

    }, { pollingInterval: 10 * 1000 })

    const history = useHistory()

    const toLine = (lineCode: any) => {
        history.push({
            pathname: '/line',
            search: '?lineCode=' + lineCode
        })
    }
    let lines = [
        { power: 0, count: 0, e: 0 },
        { power: 0, count: 0, e: 0 },
        { power: 0, count: 0, e: 0 },

    ]
    if (loading) return content
    if (error) {
        content = <Error />
        return content
    }
    lines[0].power = data[0] / 1000
    lines[0].count = data[1]
    lines[0].e = data[2]
    console.log(data[3])
    if (!data[3]) {

        content = <LineLayout justify='between' padding={[25, 50, 0, 50]} >
            {
                lines.map((item, idx) => {
                    return <div className='entry-item wenzhou' onClick={() => { toLine(idx + 1) }}>
                        <div className='entry-item-title'>#{idx + 1}</div>
                        <LineLayout justify='start' padding={[5, 0, 0, 15]}>
                            <Dashboard id={`w${idx}`} height={130} data={item.power / 200 * 100} />
                            <div style={{ marginTop: '30px', marginLeft: '10px', }}>
                                <DigitalFlop count={item.power} title='功率' type='count' size={0.7} suffix='kW' />
                            </div>
                        </LineLayout>
                        <div className='line-info'><span>{_.round(item.count / 10000, 1)} 万双  |  {item.e} 度</span></div>

                        {/* <div className='line-info'><span>累积产量： {_.round(item.count / 10000, 1)}万双</span></div>
                    <div className='line-info'><span>累积能耗：{item.e}度</span></div> */}

                        <div className='jump-btn'>跳转</div>
                    </div>
                })
            }
        </LineLayout >
        return content

    }

    content = <LineLayout justify='between' padding={[25, 50, 0, 50]} >
        {
            lines.map((item, idx) => {
                return <div className='entry-item' onClick={() => { toLine(idx + 1) }}>
                    <div className='entry-item-title'>#{idx + 1}</div>
                    <Dashboard id={`w${idx}`} height={130} data={item.power / 200 * 100} />
                    <div className='line-power'><span style={{ color: '#05d380' }}>{_.round(item.power)}</span>kw</div>
                    <div className='line-info'><span>{_.round(item.count / 10000, 1)}万双 | {item.e}度</span></div>
                    <div className='jump-btn'>跳转</div>
                </div>
            })
        }
    </LineLayout >
    return content
}
