import React from 'react'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineLayout } from '@/layout'
import { LineChart } from '@/components/chart/lineChart'
import * as API from '@/api/request'
import moment from 'moment'
import _ from 'lodash'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import './index.scss'
import { store } from '@/store'
import Icon from '@/components/icon/icon'
// data 0累计产量 1今日产量  2 15天产量

const lineCode = searchParams().lineCode
interface Props {
  data?: Array<any>
  jumpTo?: string
}

const count = (count: number) => {
  if (count >= 10000) {
    return _.round(count / 10000, 1)
  }
  return count
}

const suffix = (count: number) => {
  if (count >= 10000) {
    return '万双'
  }
  return '双'
}


/**
 * 处理接口返回的数据 props.data[2]
 * 获得折线图需要的一个对象 {xAxis:[],yAxis:[]}
 * api返回格式
 * [
  {
    "timeRange": {
      "type": 0,
      "beginTime": "2021-06-23T03:32:14.120Z",
      "endTime": "2021-06-23T03:32:14.120Z"
    },
    "count": 0
  }
]
 * @param data
 * @returns
 */

const handleChartData = (data: Array<any>) => {
  let result = { xAxis: [], yAxis: [] }
  if (!Array.isArray(data)) return result
  if (data.length === 0) return result
  result.yAxis = data.map((item: any) => item.count)
  result.xAxis = data.map((item: any) => moment(item.timeRange.beginTime).format('M/D'))
  return result
}
let content = <Loading />

const Production = () => {
  const projectShortCode = store.getState().projectInfo.shortCode.toUpperCase()
  //产量
  let { data, loading, error } = useRequest(
    () => {
      return Promise.all(
        [
          API.Production.getTotalCount(lineCode),
          API.Production.getTodayCount(lineCode),
          API.Production.getCountByLast15Days(lineCode),
          API.Production.getHistoricHighCountByDay(lineCode)
        ]
      )
    },
    { pollingInterval: 3 * 1000 }
  )


  if (loading) return content
  if (error) {
    content = <Error />
    return content
  }

  content = <React.Fragment>
    <LineLayout justify="start" padding={[20, 0, 0, 30]}>
      <DigitalFlop
        type='count'
        title="累计产量"
        count={count(data[0])}
        suffix={suffix(data[0])}
        minWidth={200}
      />
      <DigitalFlop title="今日产量" decimals={0} count={moment().format('YYYY-MM-DD') === '2022-08-18' ? count(data[1]) + 50 : count(data[1])} suffix={suffix(data[1])} type='count' />
      {
        projectShortCode === 'JDD3' ?
          <div className='max-production' style={{ height: '44px', width: '150px', position: 'absolute', top: '120px', left: '230px', fontSize: "40px" }}>
            <div className='max-production-container'>
              {data[3].toLocaleString()}
              <div>
                <p className='max-production-unit'>双</p>
              </div>
              {/* <div className="box1"></div> */}
            </div>
            {/* <p className='max-production-label'>
              <Icon url={require('../../../assets/images/gj2.png')} size={2.5} />
            </p> */}
            <p className='max-production-title'>
              最高
            </p>
          </div>
          : null
      }
    </LineLayout>
    <LineChart margin="30px 0 0 0" id="production-thumbnail" height={215} title="最近15天产量（双）" titleSize={18} data={handleChartData(data[2])} axisStyle={{ fontSize: 15 }} />
  </React.Fragment>
  return content
}


export { Production }

