/*
 * @Description:基础趋势图组件
 * @Author: huixu
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './lineOptions.js'
import './chart.scss'
import moment from 'moment'

interface AxisStyle {
  fontSize?: number,
  color?: string
}
interface Props {
  id: string
  height?: string | number,
  title?: string
  margin?: string
  data?: any,
  titleSize?: number,
  axisStyle?: AxisStyle,
  warpHeight?: number,
  formatter?: Function,
}
interface State {
  chart: echarts.ECharts
  margin: string
}

//@ts-ignore
const chartStyle = option.series[0]

const areaStyle = {
  color: {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#3680f8' // 0% 处的颜色
    }, {
      offset: 0.7, color: '#102334' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }
}

const handlePromptBoxTime = (time: any) => {
  if (time < 10) {
    return `0${time}:00`;
  } else {
    return `${time}:00`;
  }
}

export class LineChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      chart: null,
      margin: props.margin,
    }
  }

  drawChart() {
    if (this.state.chart) {
      this.setChart()
      return false
    }
    this.setState(
      {
        chart: echarts.init(document.getElementById(`line-chart-${this.props.id}`) as HTMLDivElement),
      },
      () => {
        this.setChart()
      }
    )
  }
  setChart() {
    // console.log(this.props.data)
    option.xAxis[0].axisLine.fontSize = 30
    option.xAxis[0].axisLabel.textStyle.fontSize = this.props.axisStyle ? this.props.axisStyle.fontSize : 22
    option.yAxis[0].axisLabel.fontSize = this.props.axisStyle ? this.props.axisStyle.fontSize : 22
    option.xAxis[0].data = this.props.data.xAxis
    option.tooltip.trigger = 'axis';
    option.tooltip.formatter = this.props.formatter;
    if (this.props.data.series) {
      this.props.data.series.forEach((item: any, idx: number) => {
        option.series[idx] = { ...chartStyle, data: item.data, name: item.name }
      });
      // console.log(option.series)
    } else {
      option.legend.show = true
      option.series = []
      option.series[0] = { ...chartStyle, areaStyle, data: this.props.data.yAxis }
      option.series[0].itemStyle = {
        normal: {
          color: '#fff', //改变折线点的颜色
          lineStyle: {
            color: '#3680f8' //改变折线颜色
          }
        }
      }

    }
    if (this.props.data.xAxis.length === 0 || this.props.data.yAxis.length === 0) {
      return 0
    } else {
      this.state.chart.setOption(option as any, true)
    }
  }
  componentDidMount() {
    this.drawChart()
  }
  componentDidUpdate() {
    this.drawChart()
  }

  render() {
    return (
      <div style={{ margin: this.state.margin, width: '100%', height: `${this.props.warpHeight === null || this.props.warpHeight === undefined ? '100%' : this.props.warpHeight + 'px'}` }}>
        <div className="chart-title" style={{ fontSize: this.props.titleSize + 'px' }}>
          <div className='title-point' style={{ height: this.props.titleSize + 'px', width: this.props.titleSize + 'px' }} />
          {this.props.title}
        </div>
        <div id={`line-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height }}>
        </div>
        {/* <div className='number'>30</div> */}

      </div>
    )
  }
}
